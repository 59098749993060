











































import { Component, Inject } from 'vue-property-decorator'
import { SizeMap } from '../../../dsl/composables'

import { AllowedAddon, ContentSneakPeakData, ImageData } from '../../../contexts'
import { logger, PARENT_CONTENT_DATA_KEY } from '../../../support'

import { AbstractModuleUi } from '../../abstract/ui'

import { ArticleHeaderModule } from '../ArticleHeader.contracts'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ArticleHeaderModuleUi>({
  name: 'ArticleHeaderModuleUi',
  components: {},
  created () {
    if (typeof this.contentSneakPeakPicker === 'undefined') {
      logger(
        '[ArticleHeader]: PARENT_CONTENT_DATA_KEY is [undefined]! Unable to resolve the component!',
        'warn'
      )
    }
  }
})
export class ArticleHeaderModuleUi extends AbstractModuleUi<ArticleHeaderModule> {
  /**
   * Inject article content
   */
  @Inject({ from: PARENT_CONTENT_DATA_KEY })
  public readonly contentSneakPeakPicker!: ContentSneakPeakData

  /**
   * Get article lead
   */
  public get articleLead () {
    if (!this.contentSneakPeakPicker.properties) return
    return this.contentSneakPeakPicker.properties[AllowedAddon.Lead]
  }

  public get category (): AnyObject {
    const properties = this.contentSneakPeakPicker.properties
    return (properties[AllowedAddon.Categories] as AnyObject[])[0]
  }

  public get categoryUrl (): string {
    return `${this.contentSneakPeakPicker.parent?.slug}/?category=${this.category.id}`
  }

  public get hasCategoryAddon (): boolean {
    const properties = this.contentSneakPeakPicker.properties
    return properties && (AllowedAddon.Categories in properties) && properties[AllowedAddon.Categories].length > 0
  }

  /**
   *
   */
  public get sizeMap () {
    return SizeMap
  }

  /**
   * Determines whether cover **IMAGE** is present.
   */
  public get hasCoverPhoto (): boolean {
    if (!this.contentSneakPeakPicker.properties) {
      return false
    }

    return typeof this.contentSneakPeakPicker.properties[AllowedAddon.Cover] !== 'undefined' &&
      (Object.keys(this.contentSneakPeakPicker.properties[AllowedAddon.Cover]).includes('image'))
  }

  public get coverPhoto (): Pick<ImageData, 'url' | 'caption' | 'alt'> {
    if (!this.hasCoverPhoto) {
      return { url: '' }
    }

    const image: ImageData = (this.contentSneakPeakPicker.properties[AllowedAddon.Cover] as AnyObject).image

    return {
      alt: image.alt ?? '',
      url: image.url ?? '',
      caption: image.caption ?? ''
    }
  }
}

export default ArticleHeaderModuleUi
